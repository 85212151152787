.comonent-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10040;
  max-height: 100%; /* Adjust as needed */
  overflow-y: auto;
  transform: translate(-50%, -50%);
}

